import Field from './field.js';

// An abstraction to allow usage of coordinates in Yards and display in pixels

export default class FieldViewport {
    x: number;

    y: number;

    w: number;

    h: number;

    field: Field;

    flipped: boolean;

    zoomFactor: number;

    containerWidth: number;

    containerHeight: number;

    constructor(
        field: Field,
        flipped = false,
        zoomFactor = 1,
        elWidth: number = field.width,
        elHeight: number = field.height
    ) {
        this.field = field;
        this.containerWidth = elWidth;
        this.containerHeight = elHeight;

        const pixelRatio = elWidth / elHeight;

        const fieldRatio = field.getFullWidth() / field.getFullHeight();

        if (pixelRatio > fieldRatio) {
            this.w = elHeight * fieldRatio;
            this.h = elHeight;
            this.y = 0;
            this.x = (elWidth - this.w) / 2;
        } else {
            this.w = elWidth;
            this.h = elWidth / fieldRatio;
            this.y = (elHeight - this.h) / 2;
            this.x = 0;
        }

        this.flipped = flipped;
        this.zoomFactor = zoomFactor;
    }

    clampCoordsPx(coords: ScreenPoint): ScreenPoint {
        return this.getPixelCoords(this.field.clampCoords(this.getFieldCoords(coords)));
    }

    getPixelLength(w: number): number {
        return (w / this.field.getFullWidth()) * this.w * this.zoomFactor;
    }

    getPixelX(x: number): number {
        const px = this.getPixelLength(x + this.field.sideline.left);
        if (this.flipped) {
            return this.w * this.zoomFactor - px + this.x;
        }
        return px + this.x;
    }

    getPixelY(y: number): number {
        const py = this.getPixelLength(y + this.field.sideline.top);
        if (this.flipped) {
            return this.h * this.zoomFactor - py + this.y;
        }
        return py + this.y;
    }

    getPixelCoords(pt: FieldPoint): ScreenPoint {
        return {
            space: 'screen',
            x: this.getPixelX(pt.x),
            y: this.getPixelY(pt.y),
        };
    }

    getPixelLine<L extends Line>(line: L): L {
        return {
            ...line,
            a: this.getPixelCoords(line.a),
            b: this.getPixelCoords(line.b),
        };
    }

    getFieldLine<L extends Line>(line: L): L {
        return {
            ...line,
            a: this.getFieldCoords(line.a),
            b: this.getFieldCoords(line.b),
        };
    }

    getFieldLength(w: number): number {
        const px = (w / this.w) * (1 / this.zoomFactor);
        const fieldX = px * this.field.getFullWidth();
        return fieldX;
    }

    getFieldX(x: number): number {
        const px = ((x - this.x) / this.w) * (1 / this.zoomFactor);
        const fieldX = px * this.field.getFullWidth();
        if (this.flipped) {
            return this.field.getFullWidth() - fieldX - this.field.sideline.left;
        }
        return fieldX - this.field.sideline.left;
    }

    getFieldY(y: number): number {
        const px = ((y - this.y) / this.h) * (1 / this.zoomFactor);
        const fieldY = px * this.field.getFullHeight();
        if (this.flipped) {
            return this.field.getFullHeight() - fieldY - this.field.sideline.top;
        }
        return fieldY - this.field.sideline.top;
    }

    getFieldCoords(pt: ScreenPoint): FieldPoint {
        return {
            x: this.getFieldX(pt.x),
            y: this.getFieldY(pt.y),
        };
    }

    getFlippedX(px: number): number {
        return this.w * this.zoomFactor - px + this.x;
    }

    getFlippedY(py: number): number {
        return this.h * this.zoomFactor - py + this.y;
    }

    getFlippedCoords(pt: ScreenPoint): ScreenPoint {
        return {
            x: this.getFlippedX(pt.x),
            y: this.getFlippedY(pt.y),
        };
    }
}
