import {
    useState,
    ReactNode,
    MutableRefObject,
    useEffect,
    createContext,
    useContext,
    useCallback,
    useRef,
    ComponentType,
} from 'react';

interface UseValidationResult {
    errors: any;
    validateRef: MutableRefObject<() => Promise<boolean>>;
    submitted: boolean;
    setSubmitted: (value: boolean) => void;
}

type ValidationContextValue = Omit<UseValidationResult, 'errors'> & {
    addValidator: (callback?: AnyFunction) => any;
    removeValidator: (callback?: AnyFunction) => any;
};

export const ValidationContext = createContext<ValidationContextValue>({
    addValidator: () => null,
    removeValidator: () => null,
    validateRef: { current: async () => true },
    submitted: false,
    setSubmitted: () => null,
});

export const isValid = (errors: any): boolean => Object.values(errors).filter(Boolean).length === 0;

interface Props {
    children: ReactNode;
    validateRef: MutableRefObject<() => Promise<boolean>>;
}

export const ValidationProvider: FC<Props> = ({ children, validateRef }) => {
    const [callbacks, setCallbacks] = useState([]);
    const [submitted, setSubmitted] = useState(false);

    const addValidator = (callback: AnyFunction) => {
        setCallbacks((currentCallbacks) => [...currentCallbacks, callback]);
    };

    const removeValidator = (callback: AnyFunction) => {
        setCallbacks((currentCallbacks) => {
            const index = currentCallbacks.indexOf(callback);
            if (index >= 0) {
                return [...currentCallbacks.slice(0, index), ...currentCallbacks.slice(index + 1)];
            }
            return currentCallbacks;
        });
    };

    useEffect(() => {
        // eslint-disable-next-line no-param-reassign
        validateRef.current = async () => (await Promise.all(callbacks.map((fn) => fn()))).every(isValid);

        return () => {
            // eslint-disable-next-line no-param-reassign
            validateRef.current = async () => true;
        };
    }, [validateRef, callbacks]);

    return (
        <ValidationContext.Provider
            value={{
                addValidator,
                removeValidator,
                validateRef,
                submitted,
                setSubmitted,
            }}
        >
            {children}
        </ValidationContext.Provider>
    );
};

export const useValidation = (
    cb: (...args: any[]) => any = () => ({}),
    dependencies: any[] = []
): UseValidationResult => {
    const [errors, setErrors] = useState({});
    const { addValidator, removeValidator, validateRef, submitted, setSubmitted } = useContext(ValidationContext);
    const resultsRef = useRef(null);

    const callback = useCallback(async (...args) => {
        resultsRef.current = await cb(...args);

        return resultsRef.current;
    }, dependencies);

    const cachedCallback = useCallback(async (...args) => {
        if (!resultsRef.current) {
            resultsRef.current = await cb(...args);
        }

        return resultsRef.current;
    }, dependencies);

    useEffect(() => {
        resultsRef.current = null;
        addValidator(callback);

        return () => removeValidator(callback);
    }, [callback]);

    // TODO: make the triggering of a manual validation more explicit
    // do we need to use an effect on submitted?
    useEffect(() => {
        if (submitted) {
            (async () => {
                const err = await cachedCallback();

                setErrors(err);
            })();
        }
    }, [callback, submitted]);

    return { errors, validateRef, submitted, setSubmitted };
};

export const addValidation = (WrappedComponent: ComponentType<any>) => {
    return function WithValidation(props) {
        const validateRef = useRef(null);

        return (
            <ValidationProvider validateRef={validateRef}>
                <WrappedComponent {...props} />
            </ValidationProvider>
        );
    };
};
